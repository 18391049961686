import _ from 'lodash';

import { 
  SET_REQUIRED_ACTION,
  SET_REQUIRED_ACTIONS,
  SET_NEXT_REQUIRED_ACTION,
  ADD_USER,
  LOGOUT,
  SET_ACTION_LIMIT,
  WEBPUSH_UNKNOWN,
  WEBPUSH_SET_STATUS,
} from '../constants';

const defaultState = {
  requireAction: '',
  user: null,
  limit: 0,
  webpushStatus: WEBPUSH_UNKNOWN,
};

const sortingPriority = {
  REQUIRE_SELFIE_COMMITMENT: 4,
  REQUIRE_LOCATION: 3,
  REQUIRE_SELFIE_1: 2,
  REQUIRE_SELFIE_2: 1,
}

export default (state = defaultState, action) => {
  let requiredActions;
  let activeAction;
  switch (action.type) {
    case SET_REQUIRED_ACTION:
      state = {
        ...state,
        requireAction: action.requiredAction,
        prompt: action.prompt || '',
      }

      break;
    case SET_REQUIRED_ACTIONS:
      requiredActions = action.requiredActions.map((d) => {
        return d.data();
      }).sort((a, b) => {
        const path = 'meta.action';
        const aScore = sortingPriority[_.get(a, path, '')] || 99;
        const bScore = sortingPriority[_.get(b, path, '')] || 99;
        return bScore-aScore;
      });
      activeAction = getActionMessage(requiredActions.shift());

      state = {
        ...state,
        requireAction: activeAction.requiredAction,
        prompt: activeAction.prompt || '',
        requiredActions,
      }

      break;
    case SET_NEXT_REQUIRED_ACTION:
      ({ requiredActions = [] } = state);
      activeAction = getActionMessage(requiredActions.shift());
      state = {
        ...state,
        requireAction: activeAction.requiredAction,
        prompt: activeAction.prompt || '',
        requiredActions,
      }
      break;
    case ADD_USER:
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
        limit: _.get(action, 'user.influenceTokens',  state.limit),
      }
      break;
    case LOGOUT:
      state = {
        ...defaultState,
      }
      break;
    case SET_ACTION_LIMIT:
      state = {
        ...state,
        limit: action.limit,
      }
      break;
    case WEBPUSH_SET_STATUS:
      state = {
        ...state,
        webpushStatus: action.webpushStatus,
      }
      break;
    default:
      break;
  }

  return state;
}


function getActionMessage(action) {
  const requiredAction = _.get(action, 'meta.action', '');
  const prompt = _.get(action, 'meta.message', '');
  return { requiredAction, prompt };
}