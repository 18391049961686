import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router'
import { push } from 'connected-react-router';

import {
  logout,
  requireSignin,
  requestWebpushPermission,
} from '../../actions/app';

import { isFacebookApp, iOS } from '../../services/utils';

import Button from 'muicss/lib/react/button';

import { 
  // WEBPUSH_DENIED, 
  WEBPUSH_UNKNOWN } from '../../constants';

import CookieModal from '../../components/CookieModal';
import LoadingBar from '../../components/LoadingBar';
import NavBar from '../NavBar';
import Issues from '../Issues';
import CostModalContainer from '../CostModalContainer';

import './App.css';

const IssueForm = lazy(() => import('../IssueForm'));
const RequiredActions = lazy(() => import('../RequiredActions'));

export function App ({ match, webpushStatus, user, logout, goto, showSigninPanel, enableWebpush }) {
  const requestNotification = !iOS() && !isFacebookApp() && user && [WEBPUSH_UNKNOWN].includes(webpushStatus);

  const isInTaxApp = window.location.href.includes('for-better-philippines');
  return (
    <div id="app">
      {
        !isInTaxApp && (
          <div className="publiko-message-button" onClick={() => goto('/for-better-philippines')}>How much of your money goes to the government? <Button>Find Out Now</Button></div>
        )
      }
      
      <NavBar />
      <Switch>
        <Route exact path="/" component={() => (<Home goto={goto} />)}/>
        <Route exact path="/index.html" component={Home}/>
        <Route path="/issues/:issue_id" component={IssuePage}/>
        <Route path="/notifications" component={NotificationsPage}/>
        <Route path="/faq" component={FAQPage}/>
        <Route path="/for-better-philippines" component={TaxPage}/>
        <Route path="/for-better-philippines/:tax" component={TaxPage}/>
        <Route path="/influence-tokens" component={InfluenceTokenPage}/>
        <Route path="/management" component={ManagementPage}/>
      </Switch>
      {requestNotification && (
        <div id="webpush-request-enable-bar">
          <div>Please enable notifications to know the progress about issues you follow.</div>
          <Button onClick={enableWebpush}>ENABLE</Button>
        </div>
      )}

      <footer>
        <a href="/faq" onClick={(e) => {e.preventDefault(); goto('/faq')}}>FAQ</a> | 
        <a target="_blank" href="/tos.html"> Terms of service</a> | 
        <a target="_blank" href="/privacy.html"> Privacy</a>
      </footer>
      {
        !isInTaxApp && (
          <Suspense fallback={<LoadingBar />}>
            <RequiredActions match={match} />
          </Suspense>
        )
      }
      <CostModalContainer />
    </div>
  );
}

const TaxPage = () => {
  const LifetimeTax = lazy(() => import('../LifetimeTax'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <LifetimeTax />
    </Suspense>
  )
}

const Home = ({ goto }) => {
  return (
    <React.Fragment>
      <Suspense fallback={<LoadingBar />}>
        <IssueForm />
      </Suspense>
      <Issues />
      <CookieModal name="tax-app-ad">
        <div id="tax-app-ad" className="publiko-message-card-button">
          <img src="/publiko-logo.svg" alt="publiko"/>
          <div>
            <p>Take politics as seriously as you would with money.</p>
            <Button color="primary" variant="raised" onClick={() => goto('/for-better-philippines')}>Find out why.</Button>
          </div>
        </div>
      </CookieModal>
    </React.Fragment>
  )
}

const NotificationsPage = () => {
  const Notifications = lazy(() => import('../Notifications'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <Notifications />
    </Suspense>
  );
}

const IssuePage = ({ match }) => {
  const IssuePage = lazy(() => import('../IssuePage'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <IssuePage match={match}/>
    </Suspense>
  );
}

const InfluenceTokenPage = () => {
  const InfluencePage = lazy(() => import('../InfluenceTokenPage'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <InfluencePage/>
    </Suspense>
  );
}

const FAQPage = () => {
  const FAQ = lazy(() => import('../../components/FAQ'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <FAQ/>
    </Suspense>
  );
}

const ManagementPage = () => {
  const Management = lazy(() => import('../ManagementPage'));
  return (
    <Suspense fallback={<LoadingBar />}>
      <Management/>
    </Suspense>
  );
}

const mapStateToProps = ({ app: { user, webpushStatus } }) => {
  return {
    webpushStatus,
    user,
  }
};

const mapDispatchToProps = dispatch => ({
  goto: (page) => dispatch(push(page)),
  logout: () => dispatch(logout()),
  showSigninPanel: () => dispatch(requireSignin()),
  enableWebpush: () => dispatch(requestWebpushPermission()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
