import React from 'react';

import './index.css';

import Icon, { ICON } from '../Icon';

const Modal = ({ children, onCancel }) => {
  return(
    <div className="modal">
      { onCancel && (<div className="close-cover" onClick={onCancel}/>) }
      <section>
        {children}
        { onCancel && (<Icon icon={ICON.CANCEL} size={20} onClick={onCancel} button={true}/>) }
      </section>
    </div>
  );
}

export default Modal;