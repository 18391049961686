import * as _ from 'lodash';
// import LogRocket from 'logrocket';

import {
  firebase,
  firestore,
  auth,
  setToken,
  initializeWebpush,
  functions,
} from '../services/firebase';

import {
  ADD_USER,
  ADD_NOTIFICATION,
  LOGOUT,
  SET_REQUIRED_ACTION,
  SET_REQUIRED_ACTIONS,
  SET_NEXT_REQUIRED_ACTION,
  REQUIRE_SIGNIN,
  SET_ACTION_LIMIT,
  WEBPUSH_SET_STATUS,
  WEBPUSH_GRANTED,
  WEBPUSH_DENIED,
  WEBPUSH_UNKNOWN,
  REQUIRE_SELFIE_2,
} from '../constants';

// ENABLE_DISABLE_SELFIE
// import { addNotificationUserStatus } from './notification';

let previousUserCopy = null;
export const initializeUser = (uid) => {
  return (dispatch) => {   
    const unlisten = firestore.doc(`users/${uid}`).onSnapshot((snapshot) => {

      if (snapshot.exists) {
        const user = snapshot.data();

        // LogRocket.identify(uid, {
        //   name: user.displayName,
        // });

        if (previousUserCopy && previousUserCopy.influenceTokens !== user.influenceTokens)  {
          dispatch(setActionLimit(user.influenceTokens));
        }

        if (!previousUserCopy || previousUserCopy.status !== user.status) {
          dispatch({
            type: ADD_USER,
            user: {
              uid,
              ...user,
            },
          });
  
          dispatch(determineWebpushStatus());
  
          if (user.status !== 'READY') {
            dispatch(getRequiredActions(user));
          } else {
            // ENABLE_DISABLE_SELFIE
            // dispatch(addNotificationUserStatus(user.status));
            unlisten();
          }
          previousUserCopy = user;
        }
      }
    });
    return dispatch(checkToken());
  }
}

export const getRequiredActions = ({ status }) => {
  const { uid } = auth.currentUser;
  return (dispatch) => {
    firestore.collection(`users/${uid}/history`)
      .where('type', '==', 'REQUIRED_ACTION')
      .where('status', '==', 'OPEN')
      .get()
      .then((snapshots) => {
        // ENABLE_DISABLE_SELFIE
        // dispatch(addNotificationUserStatus(status));
        if (snapshots.size) {
          dispatch({
            type: SET_REQUIRED_ACTIONS,
            requiredActions: snapshots.docs,
          });
        } else {
          dispatch(nextRequiredAction());       
        }
      });
  }
}

export const bootstrapWebpush = () => {
  return (dispatch) => {
    const messaging = initializeWebpush();
    if (messaging) {
      messaging.requestPermission()
        .then(() => {
          setToken();
          messaging.onTokenRefresh(() => setToken());
          messaging.onMessage((payload) => {
            dispatch(addNotification(payload));
          });

          dispatch({
            type: WEBPUSH_SET_STATUS,
            webpushStatus: WEBPUSH_GRANTED,
          });
        })
        .catch(() => {
          dispatch({
            type: WEBPUSH_SET_STATUS,
            webpushStatus: WEBPUSH_DENIED,
          });
        });
    } else {
      dispatch({
        type: WEBPUSH_SET_STATUS,
        webpushStatus: WEBPUSH_DENIED,
      });
    }
  }
}
export const determineWebpushStatus = () => {
  return (dispatch) => {
    const permission = _.get(window, 'Notification.permission', 'default');
    let webpushStatus = WEBPUSH_UNKNOWN;
    switch (permission) {
      case 'denied':
        webpushStatus = WEBPUSH_DENIED;
        break;
      case 'granted':
        dispatch(bootstrapWebpush());
        webpushStatus = WEBPUSH_GRANTED;
        break;
      case 'default':
      default:
        webpushStatus = WEBPUSH_UNKNOWN;
        break;
    }
  
    dispatch({
      type: WEBPUSH_SET_STATUS,
      webpushStatus,
    });
  }
}

export const requestWebpushPermission = () => {
  return async (dispatch) => {
    const permission = _.get(window, 'Notification.permission', 'default');

    switch (permission) {
      case 'denied':
        // if it was already blocked therefore no prompt will be shown in the user
        // strategy is to give them the knowledge to enable it manually
        window.open('https://www.google.com/search?q=how+do+i+turn+on+browser+notifications');
        break;
      case 'granted':
      case 'default':
      default:
        dispatch(bootstrapWebpush());
        break;
    }
  }
}

export const logout = () => {
  return async (dispatch) => {

    dispatch({
      type: LOGOUT,
    });
    await auth.signOut();
  }
}

export const requireSignin = (prompt = '') => {
  return {
    type: SET_REQUIRED_ACTION,
    requiredAction: REQUIRE_SIGNIN,
    prompt,
  }
}

export const cancelSignin = () => {
  return {
    type: SET_REQUIRED_ACTION,
    requiredAction: '',
  }
}

export const addLocation = (location) => {
  return (dispatch) => {
    functions.httpsCallable('addUserLocation')(location)
      .then(() => {
        dispatch({
          type: ADD_USER,
          user: { location },
        });
        dispatch({
          type: SET_NEXT_REQUIRED_ACTION,
        });
      })
  }
}

export const addSelfie = (selfieType, selfie_id) => {
  return (dispatch) => {
    const { uid } = auth.currentUser;

    const selfieHistory = {
      cost: 0,
      type: selfieType.replace('REQUIRE','ADD'),
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      meta: {
        selfieType,
        selfie_id,
      }
    }

    return firestore.collection(`users/${uid}/history`)
      .add(selfieHistory)
      .then(() => selfieType === REQUIRE_SELFIE_2 && functions.httpsCallable('verifySelfies')())
      .then(() => {
        dispatch(nextRequiredAction());
      });
  }
}

export const nextRequiredAction = () => {
  return { type: SET_NEXT_REQUIRED_ACTION };
}

export const setActionLimit = (limit) => {
  return {
    type: SET_ACTION_LIMIT,
    limit,
  }
}

export const addNotification = ({ notification, data }) => {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      id: data.notification_id,
      notification,
      data,
    }
  }
}

export const checkToken = () => {
  return (dispatch) => {
    return functions.httpsCallable('getInfluenceToken')()
      .then(({ data }) => {
        const limit = data.value || 0;
        dispatch(setActionLimit(limit));
      })
  }
}