import React from 'react';

import { ICON as icons, COLOR as colors } from '../../constants';


class Icon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fill: props.color,
      opacity: 1,
    }
  }

  onMouseOver() {
    if (this.props.button) {
      this.setState({
        fill: this.props.hover_color || this.props.color,
        opacity: 0.6,
      });
    }
  }

  onMouseOut() {
    this.setState({ fill: this.props.color, opacity: 1 });
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.color !== this.props.color) {
      this.setState({ fill: this.props.color });
    }
  }

  render() {
    const { icon, size, width, height, button } = this.props;
    const styles = {
      svg: {
        display: 'block',
        verticalAlign: 'middle',
        overflow: 'display',
        opacity: this.state.opacity,
      },
      path: {
        fill: (this.state.fill || colors.RED),
      },
    };

    if (button) {
      styles.svg.cursor = 'pointer';
    }

    const iconPaths = typeof icon === 'string' ? [icon]: (typeof icon.icon === 'string' ? [icon.icon]:icon.icon);
    const viewBox = icon.viewBox || '0 0 1024 1024';
  
    return (
      <svg
        className="svg-icon"
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onClick={this.onClick.bind(this)}
        style={styles.svg}
        width={`${width || size}px`}
        height={`${height || size}px`}
        viewBox={viewBox}
      >
        {
          iconPaths.map((d, i) => (<path key={i} style={styles.path} d={d}/>))
        }
      </svg>
    );
  }
}

export const ICON = icons;
export const COLOR = colors;

Icon.defaultProps = {
  size: 16,
};

export default Icon;