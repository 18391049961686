import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import './services/firebase';

import { history, default as store } from './store';

import './index.css';

import { unregister } from './registerServiceWorker';

import App from './containers/App';


const render = () => {
  // import('logrocket')
  //   .then((logrocket) => {
  //     logrocket.default.init('oainbi/publiko');
  //   });

  import('react-ga')
    .then((ReactGA) => {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    });

  ReactDOM.render(
    <Provider store={store()}>
      <ConnectedRouter history={history}>
        <div>
          <Route path="/" component={App}/>
        </div>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}

if (module.hot && process.env.NODE_ENV==="development") {
  module.hot.accept();
  render();
} else {
  render();
}

unregister(); // temporary until we can master PWA
