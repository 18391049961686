import { 
  national,
  SET_TAB,
  SET_SCOPE,
  SET_ISSUES,
  SET_ISSUE_SCROLL,
  SET_CATEGORY,
} from '../constants';

const defaultState = {
  tab: 0, // 0 = regular issues, 1 = top issues
  scope: national,
  issues: [],
  scroll: 0,
  category: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TAB:
      state = {
        ...defaultState,
        tab: action.tab,
      }
      break;
    case SET_SCOPE:
      state = {
        ...state,
        scope: action.scope,
      }
      break;
    case SET_ISSUES:
      state = {
        ...state,
        issues: action.issues,
      }
      break;
    case SET_ISSUE_SCROLL:
      state = {
        ...state,
        scroll: action.scroll || 0,
      }
      break;
    case SET_CATEGORY:
      state = {
        ...state,
        category: action.category || '',
      }
      break;
    default:
      break;
  }

  return state;
}