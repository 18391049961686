import { SHOW_COST_MODAL, RESET_COST_MODAL } from '../constants';

const defaultState = {
  cost: 0,
  type: '',
  actionMessage: '',
  onConfirm: null,
  onCancel: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_COST_MODAL:
      state = {
        ...action.config,
      }
      break;
    case RESET_COST_MODAL:
      state = {
        ...defaultState,
      }
      break;
    default:
      break;
  }

  return state;
}