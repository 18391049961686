import _ from 'lodash';
import { ADD_NOTIFICATION } from '../constants';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      state = _.uniqBy([...state, action.notification ], 'id');
      break;
    default:
      break;
  }

  return state;
}