import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import createHistory from 'history/createBrowserHistory';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from './reducers/rootReducer';

export const history = createHistory();
const historyMiddleware = routerMiddleware(history);

const store = createStore(
  connectRouter(history)(rootReducer),
  applyMiddleware(historyMiddleware, thunk));

if (module.hot) {
  module.hot.accept('./reducers/rootReducer', () => {
    const nextRootReducer = require('./reducers/rootReducer');
    store.replaceReducer(nextRootReducer);
  });
}

// history.listen((location) => {
//   const { dispatch } = store;
//   const { pathname } = location;
//   const [nil, base, id, action] = pathname.split('/');
// });

export default function configureStore() {
  return store;
}