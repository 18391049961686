import React from 'react';
import { connect } from 'react-redux';

import { resetCostModal } from '../../actions/costModal';

import CostModal from '../CostModal';

import './index.css';

class CostModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    }
  }

  onConfirm() {
    this.props.costModal.onConfirm();
    this.props.reset();
  }

  onCancel() {
    this.props.costModal.onCancel();
    this.props.reset();
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props.costModal;
    if (prevProps.costModal.type !== this.props.costModal.type) {
      this.setState({ show: !!type });
    }
  }

  render() {
    const { cost, type, actionMessage } = this.props.costModal;

    return (
      <>
        { this.state.show && (
          <CostModal 
            cost={cost}
            type={type}
            actionMessage={actionMessage}
            onConfirm={this.onConfirm.bind(this)}
            onCancel={this.onCancel.bind(this)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ costModal }) => {
  return {
    costModal,
  }
};

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(resetCostModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostModalContainer);