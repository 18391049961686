import { 
  national,
  SET_TAB,
  SET_SCOPE,
  SET_ISSUES,
  SET_ISSUE_SCROLL,
  SET_CATEGORY,
} from '../constants';

export const setTab = (tab = 0) => {
  return {
    type: SET_TAB,
    tab,
  }
}

export const setScope = (scope = national) => {
  return {
    type: SET_SCOPE,
    scope,
  }
}

export const setIssues = (issues = []) => {
  return {
    type: SET_ISSUES,
    issues,
  }
}


export const setIssueScroll = (scroll = window.scrollY) => {
  return {
    type: SET_ISSUE_SCROLL,
    scroll,
  }
}

export const setCategory = (category = '') => {
  return {
    type: SET_CATEGORY,
    category,
  }
}