import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import { setTab } from '../../actions/issues';

import Tabs from 'muicss/lib/react/tabs';
import Tab from 'muicss/lib/react/tab';
import LoadingBar from '../../components/LoadingBar';
import Container from 'muicss/lib/react/container';


import './index.css';

// import {
//   PUBLISHED,
//   OPEN_FOR_UPVOTING,
//   CLOSED_FOR_VIP_REACTIONS,
// } from '../../constants';

/*
  sort them by batch
    select top 5 of each  then assign batch id
    batch is YYYY-MM-dd
*/
const IssueLeaderboard = lazy(() => import('../IssueLeaderboard'));
const TopIssues = lazy(() => import('../TopIssues'));

class Issues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: props.tab || 0,
    }
  }

  componentDidMount() {
    /*
      detect if there is a tab state stored
      store issues somewhere
    */
  }

  onChange(tab) {
    this.setState({ tab })
    this.props.setTab(tab);
  }

  render() {
    return (
      <React.Fragment>
        <Container className="issues-tab-container">
          <Tabs onChange={this.onChange.bind(this)} defaultSelectedIndex={this.state.tab} justified={true}>
            <Tab value="feed" label="FEED"></Tab>
            <Tab value="top-issues" label="Top Issues"></Tab>
          </Tabs>
        </Container>
        {
          this.state.tab === 0 ?
            (
              <Suspense fallback={<LoadingBar />}>
                <IssueLeaderboard />
              </Suspense>
            ) :
            (
              <Suspense fallback={<LoadingBar />}>
                <TopIssues />
              </Suspense>
            ) 
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ issues: { tab } }) => {
  return {
    tab,
  }
};

const mapDispatchToProps = dispatch => ({
  setTab: (tab) => dispatch(setTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Issues);