import { combineReducers } from 'redux';

import app from './app';
import toggle from './toggle';
import notifications from './notifications';
import issues from './issues';
import costModal from './costModal';

export default combineReducers({
  app,
  toggle,
  notifications,
  issues,
  costModal,
});