import firebase from 'firebase';
import ReactGA from 'react-ga';
// import { push } from 'connected-react-router';
import store from '../store';

import { initializeUser } from '../actions/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const firestore = firebase.firestore();
firestore.settings({
  timestampsInSnapshots: true,
});

const functions = firebase.functions();

let messaging;
function initializeWebpush() {

  // do not remove this exception or else iOS will have a problem
  try {
    messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.REACT_APP_WEBPUSH_CERTIFICATE);
  } catch (error) {
    // https://stackoverflow.com/questions/49575679/web-push-notifications-with-ios-11-3
    messaging = null;
  }
  
  return messaging;
}

function setToken() {
  if (messaging) {
    messaging.getToken()
      .then((token) => {
        if (token && auth.currentUser) {
          const { uid } = auth.currentUser;
  
          const urn = `users/${uid}/registration_tokens/${token}`;
          // find a way not to keep triggering this event to save bandwidth?
          // check for registration token qty in users collection
          firestore.doc(urn).set({
            token,
            type: 'web',
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
          })
          //.then(() => console.log('token sent to server'))
          .catch((error) => console.error(error));
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }
}

const auth = firebase.auth();
auth.onAuthStateChanged(async (user) => {
  const { dispatch } = store();

  if (user) {
    const { uid } = user;
    ReactGA.set({ uid })
    dispatch(initializeUser(uid));
  }
});

export {
  firebase,
  firestore,
  auth,
  functions,
  setToken,
  initializeWebpush,
};
