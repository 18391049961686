import React from 'react';

import { cookieExists, setCookie } from '../../services/utils';

import './index.css';
import Modal from '../Modal';

class CookieModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    }
  }

  componentDidMount() {
    const exists = cookieExists(this.props.name);
    this.setState({ show: !exists });
  }

  onCancel() {
    this.setState({ show: false }, () => {
      setCookie(this.props.name, true, 3);
    })
  }

  render() {
    return this.state.show ? (
      <Modal onCancel={this.onCancel.bind(this)}>
        {this.props.children}
      </Modal>
    ) : (<></>);
  }
}

export default CookieModal;