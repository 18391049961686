import { TOGGLE_ACTIVATE, TOGGLE_DEACTIVATE } from '../constants';
const defaultState = {
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_ACTIVATE:
      state = {
        ...state,
        [action.key]: true,
      }
      break;
    case TOGGLE_DEACTIVATE:
      state = {
        ...state,
        [action.key]: false,
      }
      break;
    default:
      break;
  }

  return state;
 }