import {
  SHOW_COST_MODAL,
  RESET_COST_MODAL,
} from '../constants';

const costs = {
  COMMENT_ON_ISSUE: 1,
  SUPPORT_COMMENT: 1,
  SUPPORT_ISSUE: 1,
  SUBMIT_ISSUE: 1,
  REACTION_VOTE: 1,
}

export const showCostModal = (config) => {
  const cost = costs[config.type] || 1;
  return { type: SHOW_COST_MODAL, config: { ...config, cost } };
}

export const resetCostModal = () => {
  return { type: RESET_COST_MODAL };
}