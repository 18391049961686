import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import Modal from '../../components/Modal';

import { functions } from '../../services/firebase';

import { setActionLimit } from '../../actions/app';

// return this once prod is ready for selfie
// import { READY } from '../../constants';

import './index.css';

class CostModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.influenceTokens,
      enough: true, // test later if current limit < cost
      error: '',
      submitting: false,
      nextReplenishment: null,
    }
  }

  componentDidMount() {
    functions.httpsCallable('getInfluenceToken')()
    .then(({ data }) => {
      let nextReplenishment;
      const [lastReplenishment] = data.history;
      if (lastReplenishment) {
        nextReplenishment = dayjs(lastReplenishment.created_at).add(24, 'hours').format('MMM DD, YYYY hh:mm A');
      }

      const limit = data.value || 0;
      this.setState({ limit, nextReplenishment }, () => {
        this.props.setActionLimit(limit);
      });
    })
    .catch((error) => {
      this.setState({
        error: error.message,
      });
    })
  }

  onConfirm() {
    const limit = this.state.limit - 1;
    this.setState({ limit, submitting:false }, () => {
      this.props.setActionLimit(limit);
      this.props.onConfirm();
    });
  }

  render() {
    const { limit, submitting } = this.state;
    const { cost = 1, actionMessage = 'action' } = this.props;

    // reset this once selfie system is ready
    const notYetReady = false; // this.props.userStatus !== READY

    const showAlmostEmpty = !(!this.state.nextReplenishment || this.state.limit > 5) && !notYetReady;
    return (
      <Modal onCancel={this.props.onCancel}>
        <section id="cost-modal-content" className="mui--text-center">
          <div className="modal-title">Every action has a cost.</div>
          <figure>
            <img src="/publiko-logo.svg" alt="publiko"/>
          </figure>
          <div className="modal-title">It will cost <b>{cost} influence token</b> to <br/> {actionMessage}</div>
          <div className="modal-subtitle">You have <b>{limit} influence token/s</b> left.</div>

          { showAlmostEmpty && (<div className="modal-warning">You may claim your next set of <b>influence tokens</b> after <br/>{this.state.nextReplenishment}</div>)}
          { this.state.error && (<div className="modal-warning">{this.state.error}</div>) }
          { notYetReady && (<div className="modal-warning">Once your selfies are determined to be valid may claim your daily set of influence tokens. </div>) }
          <nav>
            <button onClick={this.props.onCancel}>CANCEL</button>
            <button className="confirm" onClick={this.onConfirm.bind(this)} disabled={!limit || submitting}>SUBMIT</button>
          </nav>
        </section>
      </Modal>
    )
  }
}

const mapStateToProps = ({ app: { user } }) => {
  const { status, influenceTokens } = user || {};

  return {
    userStatus: status,
    influenceTokens,
  }
};

const mapDispatchToProps = dispatch => ({
  setActionLimit: (limit) => dispatch(setActionLimit(limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostModal);