import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Icon, { ICON } from '../../components/Icon';

import './index.css';
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsCount: 0,
      influencePoints: 0,
    }
  }

  static getDerivedStateFromProps(props, state) {
    state.notificationsCount = props.notifications.length;
    state.influencePoints = props.limit;
    return state;
  }

  render() {
    const menuStyle = {}
    const userExists = this.props.user;
    
    if (!userExists) {
      menuStyle.display = 'none';
    }

    const iconSize = 40;

    const { city_manager, province_manager, admin } = _.get(this.props, 'user.roles', {});
    return (
      <nav id="nav-bar">
        <figure  onClick={() => this.props.goto('/')}>
          <img src="/publiko-logo.svg" alt="logo" width={iconSize} height={iconSize}/>
          <span>PUBLIKO</span>
        </figure>
        <ul className="mui-list--unstyled" style={menuStyle}>
          {
            (city_manager || province_manager || admin) && (
              <li onClick={() => this.props.goto('/management')}>
                <i>
                  <Icon icon={ICON.BALANCE} size={12} width={18} color="white"/>
                </i>
                <div>1</div>
              </li>
            )
          }
          <li onClick={() => this.props.goto('/notifications')}>
            <i>
              <Icon icon={ICON.BELL} size={12} width={20} color="white"/>
            </i>
            <div>{this.state.notificationsCount}</div>
          </li>
          <li onClick={() => this.props.goto('/influence-tokens')}>
            <Icon icon={ICON.PUBLIKO}  size={20} />
            <div>{this.state.influencePoints}</div>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = ({ app: { user, limit }, notifications }) => {
  return {
    user,
    limit,
    notifications,
  }
};

const mapDispatchToProps = dispatch => ({
  goto: (page) => dispatch(push(page)),
  // checkToken: () => dispatch(checkToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);